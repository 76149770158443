import { createTranslations } from '../../../utils/i18n';

interface Fields {
  ecommercePageNavItems: string;
  agenciesPageNavItems: string;
  solutionsNavItems: string;
  productNavItems: string;
  subLabelEcommercePageNavItems: string;
  subLabelAgenciesPageNavItems: string;
  freeTrial: string;
  customers: string;
}

export const translations = (locale?: string) =>
  createTranslations<Fields>({
    locale,
    data: {
      en: {
        ecommercePageNavItems: 'E-commerces',
        agenciesPageNavItems: 'Agencies',
        productNavItems: 'Home',
        solutionsNavItems: 'Solutions',
        subLabelEcommercePageNavItems: 'Built for scalling your e-commerce',
        subLabelAgenciesPageNavItems: 'Built for hyper-growth agencies',
        freeTrial: 'Learn more',
        customers: 'Cases',
      },
      pt: {
        ecommercePageNavItems: 'E-commerces',
        agenciesPageNavItems: 'Agências',
        productNavItems: 'Home',
        solutionsNavItems: 'Soluções',
        subLabelEcommercePageNavItems: 'Feito para escalar o seu negócio',
        subLabelAgenciesPageNavItems: 'Feito para agências de alto crescimento',
        freeTrial: 'Saiba mais',
        customers: 'Cases',
      },
    },
  });
