import { createTranslations } from '../../../utils/i18n';

interface Fields {
  title: string;
  buttonText: string;
}

export const translations = (locale?: string) =>
  createTranslations<Fields>({
    locale,
    data: {
      en: {
        title: 'Still in doubt?',
        buttonText: 'Contact us',
      },
      pt: {
        title: 'Ainda está em dúvida?',
        buttonText: 'Marcar conversa',
      },
    },
  });
