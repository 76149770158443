import { Flex } from '@chakra-ui/react';
import Footer from '../Footer';
import { Header } from '../Header';

interface BaseProps {
  children: React.ReactElement[];
}

export function Base({ children }: BaseProps) {
  return (
    <>
      <header>
        <Header />
      </header>

      <Flex
        pt={{ base: 20, md: 32 }}
        overflowX={'hidden'}
        h="100%"
        w="100%"
        maxW="100vw"
        flexDir="column"
        align="center"
      >
        {children}
      </Flex>

      <Footer />
    </>
  );
}
