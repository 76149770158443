import {
  Box,
  Button,
  Collapse,
  Flex,
  Icon,
  IconButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { FiChevronDown, FiChevronRight, FiMenu, FiX } from 'react-icons/fi';
import { translations } from './translations';

export function Header() {
  const { isOpen, onToggle } = useDisclosure();

  const {
    isOpen: isOpenFlags,
    onToggle: onToggleFlags,
    onClose: onCloseFlags,
  } = useDisclosure();
  const { push, locale } = useRouter();
  const t = translations(locale);

  const flagSelector = (locale = 'en') => {
    const selector = {
      en: '🇺🇸',
      pt: '🇧🇷',
    };

    if (locale === 'en' || locale === 'pt') {
      return selector[locale];
    }

    return selector.en;
  };

  return (
    <Flex
      position={'fixed'}
      zIndex={2}
      bg="white"
      width={'100%'}
      alignItems="center"
      justifyContent={'center'}
    >
      <Box flex={1} maxWidth={'7xl'}>
        <Flex
          bg="#fff"
          color={'gray.600'}
          minH={'60px'}
          w="100%"
          py={{ base: 2 }}
          px={4}
          justifyContent={'space-between'}
          align={'center'}
        >
          <Flex
            display={{ base: 'flex', md: 'none' }}
            w={{ base: 12, md: 'fit-content' }}
          >
            <IconButton
              onClick={onToggle}
              icon={
                isOpen ? (
                  <Icon w={5} h={5} as={FiX} />
                ) : (
                  <Icon w={5} h={5} as={FiMenu} />
                )
              }
              variant={'ghost'}
              aria-label={'Toggle Navigation'}
            />
          </Flex>

          <Flex justify={{ base: 'center', md: 'start' }}>
            <Flex cursor={'pointer'}>
              <Link href={'/'} passHref>
                <img alt="Adstart logo" src="/logo3.svg" width="210px" />
              </Link>
            </Flex>

            <Flex
              display={{ base: 'none', md: 'flex' }}
              alignItems={'center'}
              gap={2}
              ml={10}
            >
              <DesktopNav />
              <Flex flexDir={'column'} mr={4}>
                <Button
                  onClick={onToggleFlags}
                  fontSize="24px"
                  w="56px"
                  bg="transparent"
                  _focus={{ boxShadow: 'none' }}
                >
                  {flagSelector(locale)}
                </Button>
                <Collapse in={isOpenFlags} animateOpacity>
                  <Flex
                    flexDir={'column'}
                    position="absolute"
                    alignItems="center"
                    w="56px"
                    bg="gray.100"
                    borderBottomRadius={8}
                    zIndex={1}
                    mt={-1}
                  >
                    <Link href="/" locale="pt" passHref>
                      <Text
                        cursor={'pointer'}
                        fontSize={20}
                        w="100%"
                        display={'flex'}
                        justifyContent="center"
                        _hover={{ bg: 'gray.200' }}
                        onClick={onCloseFlags}
                      >
                        🇧🇷
                      </Text>
                    </Link>
                    <Link href="/" locale="en" passHref>
                      <Text
                        cursor={'pointer'}
                        fontSize={20}
                        w="100%"
                        display={'flex'}
                        justifyContent="center"
                        _hover={{ bg: 'gray.200' }}
                        onClick={onCloseFlags}
                      >
                        🇺🇸
                      </Text>
                    </Link>
                  </Flex>
                </Collapse>
              </Flex>
            </Flex>
          </Flex>

          <Flex
            alignContent={'center'}
            display={{ base: 'column', md: 'none' }}
          >
            <Button
              onClick={onToggleFlags}
              fontSize="24px"
              w="56px"
              bg="transparent"
              _focus={{ boxShadow: 'none' }}
            >
              {flagSelector(locale)}
            </Button>
            <Collapse in={isOpenFlags} animateOpacity>
              <Flex
                flexDir={'column'}
                position="absolute"
                alignItems="center"
                w="56px"
                bg="gray.100"
                borderBottomRadius={8}
                zIndex={1}
                mt={-1}
              >
                <Link href="/" locale="pt" passHref>
                  <Text
                    cursor={'pointer'}
                    fontSize={20}
                    w="100%"
                    display={'flex'}
                    justifyContent="center"
                    _hover={{ bg: 'gray.200' }}
                    onClick={onCloseFlags}
                  >
                    🇧🇷
                  </Text>
                </Link>
                <Link href="/" locale="en" passHref>
                  <Text
                    cursor={'pointer'}
                    fontSize={20}
                    w="100%"
                    display={'flex'}
                    justifyContent="center"
                    _hover={{ bg: 'gray.200' }}
                    onClick={onCloseFlags}
                  >
                    🇺🇸
                  </Text>
                </Link>
              </Flex>
            </Collapse>
          </Flex>

          <Stack
            justify={'flex-end'}
            direction={'row'}
            spacing={6}
            alignItems={'center'}
            display={{ base: 'none', md: 'flex' }}
            h={{ base: 0, md: '100%' }}
            w={{ base: 12, md: 'fit-content' }}
          >
            <Button
              fontWeight={'700'}
              fontSize={18}
              px={4}
              onClick={() => {
                push('/login');
              }}
              bgClip={'text'}
              bgGradient="linear(to-r, brand.blue, brand.purple)"
              _hover={{
                opacity: '0.8',
                transition: '0.2s',
              }}
            >
              Login
            </Button>

            <Button
              fontSize={18}
              onClick={() => {
                push('/trial');
              }}
              borderRadius="full"
              colorScheme="brand"
              bgGradient="linear(to-r, brand.blue, brand.purple)"
              fontWeight="bold"
              _hover={{
                opacity: '0.8',
                transition: '0.2s',
              }}
            >
              {t.freeTrial}
            </Button>
          </Stack>
        </Flex>

        <Collapse in={isOpen} animateOpacity>
          <MobileNav />
        </Collapse>
      </Box>
    </Flex>
  );
}

const DesktopNav = () => {
  const { locale } = useRouter();
  const t = translations(locale);

  const NAV_ITEMS: Array<NavItem> = [
    {
      label: t.productNavItems,
      href: '/',
    },
    {
      label: t.customers,
      href: '/customers',
    },
  ];

  return (
    <Stack direction={'row'} spacing={NAV_ITEMS.length}>
      {NAV_ITEMS.map((navItem) => (
        <Flex alignItems={'center'} key={navItem.label}>
          <Popover trigger={'hover'} placement={'bottom-start'}>
            <PopoverTrigger>
              <Box
                p={2}
                fontSize={'lg'}
                fontWeight={600}
                color={'gray.800'}
                _hover={{
                  textDecoration: 'none',
                  color: 'gray.600',
                }}
              >
                <Link href={navItem.href ?? '#'} passHref>
                  {navItem.label}
                </Link>
              </Box>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={'xl'}
                bg={'white'}
                p={4}
                rounded={'xl'}
                minW={'sm'}
              >
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Flex>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label, href, subLabel }: NavItem) => {
  const { push } = useRouter();
  return (
    <Button
      onClick={() => {
        push(href || '');
      }}
      role={'group'}
      bg="white"
      p={8}
      _hover={{ bg: 'gray.100' }}
    >
      <Flex w="100%" direction={'row'}>
        <Flex direction={'column'} alignItems="flex-start">
          <Text
            transition={'all .3s ease'}
            _groupHover={{ color: 'brand.purple' }}
            fontWeight={500}
          >
            {label}
          </Text>
          <Text fontSize={'sm'}>{subLabel}</Text>
        </Flex>
        <Flex
          transition={'all .3s ease'}
          transform={'translateX(-10px)'}
          opacity={0}
          _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
          justify={'flex-end'}
          align={'center'}
          flex={1}
        >
          <Icon color={'brand.purple'} w={5} h={5} as={FiChevronRight} />
        </Flex>
      </Flex>
    </Button>
  );
};

const MobileNav = () => {
  const { locale } = useRouter();
  const t = translations(locale);

  const NAV_ITEMS: Array<NavItem> = [
    {
      label: t.productNavItems,
      href: '/',
    },
    {
      label: t.solutionsNavItems,
      children: [
        {
          label: t.ecommercePageNavItems,
          subLabel: t.subLabelEcommercePageNavItems,
          href: '/solutions/ecommerces',
        },
        {
          label: t.agenciesPageNavItems,
          subLabel: t.subLabelAgenciesPageNavItems,
          href: '/solutions/agencies',
        },
      ],
    },
  ];

  return (
    <Stack p={4} display={{ md: 'none' }}>
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}

      <MobileNavItem label="Login" href="/login" />
      <MobileNavItem label={t.freeTrial} href="/trial" />
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }: NavItem) => {
  const { isOpen, onToggle } = useDisclosure();
  const { push } = useRouter();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        onClick={() => {
          push(href || '#');
        }}
        justify={'space-between'}
        cursor="pointer"
        align={'center'}
        _hover={{
          textDecoration: 'none',
        }}
      >
        <Text fontWeight={600} color="gray.600">
          {label}
        </Text>

        {children && (
          <Icon
            as={FiChevronDown}
            transition={'all .25s ease-in-out'}
            transform={isOpen ? 'rotate(180deg)' : ''}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={'solid'}
          borderColor={'gray.200'}
          align={'start'}
        >
          {children &&
            children.map((child) => (
              <Link key={child.label} passHref href={child.href || '#'}>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
}
