import { Button, Heading, Flex } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { translations } from './translations';

export default function KnowMore() {
  const router = useRouter();
  const t = translations(router.locale);

  return (
    <Flex
      alignItems={'center'}
      mb={10}
      minW="40%"
      px={{ base: 4, md: 0 }}
      flexDir="column"
    >
      <Heading
        fontSize={{ base: 32, md: 48 }}
        textAlign={'start'}
        mb={5}
        mt={2}
        id="book-prelaunch-reservation"
        fontWeight={'600'}
        fontFamily={'Inter'}
        color={'gray.700'}
      >
        {t.title}
      </Heading>
      <Button
        rounded={'full'}
        size={'lg'}
        fontWeight={'600'}
        fontSize={20}
        px={6}
        as="a"
        href="https://calendly.com/pedrohjleite"
        target={'_blank'}
        color="#fff"
        bgGradient="linear(to-r, brand.blue, brand.purple)"
        _hover={{
          opacity: '0.8',
          transition: '0.2s',
        }}
      >
        {t.buttonText}
      </Button>
    </Flex>
  );
}
